@use 'colors';
@use 'mixins.scss';
@use 'variables';

.modal-wrapper {
	position: relative;
	padding: 12px;

	.close-icon {
		color: rgba(colors.$accent, 0.5);
		cursor: pointer;
	}
}

.au-modal.au-modal {
	border-radius: 16px;

	&:not(.action, .no-max-width) {
		max-width: 90vw !important;
	}
}

.au-modal {
	&.no-max-width {
		max-width: inherit !important;
	}

	.mat-mdc-dialog-container {
		border-radius: 16px;
		padding: 0;
	}

	.au-dialog-container {
		position: relative;
		padding: 32px 24px;
		box-sizing: border-box;

		> .close-icon {
			position: absolute;
			top: 14px;
			right: 14px;
		}
	}

	.close-icon {
		color: rgba(colors.$accent, 0.5);
		cursor: pointer;
		border-radius: 100%;
	}

	.au-dialog-title {
		font-family: variables.$secondary-font-family;
		font-size: mixins.px-to-rem(32);
		line-height: mixins.px-to-rem(40);
	}

	.mat-mdc-dialog-actions {
		align-items: center;
		justify-content: center;
		padding: 12px 24px;
		margin: 0;
		background-color: colors.$background;
	}

	.au-dialog-description {
		max-width: 510px;
		line-height: 26px;
		margin: 30px 0 0;
		color: colors.$black-text;
	}

	.save-button {
		&:disabled {
			cursor: not-allowed;
			background-color: colors.$primary-button-disabled;
		}
	}
}

.au-cmms-alert-text {
	margin: 0;
	line-height: 30px;
}

@include mixins.media-breakpoint-down(sm) {
	.au-modal {
		.mat-mdc-dialog-actions {
			&:not(.actions-row) {
				flex-direction: column-reverse;

				au-techno-btn {
					width: 100%;
				}

				button {
					width: 100%;

					&:last-child {
						margin: 0 0 20px 0;
					}
				}
			}
		}
	}
}

@include mixins.media-breakpoint-up(xs) {
	.modal-wrapper {
		padding: 24px;
	}

	.au-modal.au-modal {
		&:not(.action) {
			max-width: 80vw !important;
		}
	}
}

@include mixins.media-breakpoint-up(bootstrap-md) {
	.modal-wrapper {
		padding: 64px;
	}

	.au-modal {
		.au-dialog-container {
			min-width: 600px;
			padding: 64px 64px 48px;

			&.small-paddings {
				padding: 48px 48px 24px;
			}

			> .close-icon {
				top: 32px;
				right: 32px;
			}
		}

		.mat-mdc-dialog-actions {
			justify-content: flex-end;
			padding: 24px 48px;
		}
	}
}
