@use './mixins';
@use './colors';
@use './variables';

%tabs-content-container {
	display: block;
	box-shadow: 0 15px 25px rgba(29, 81, 70, 0.05);
	border-radius: 0 8px 8px 8px;
	padding: 32px 20px;
	position: relative;
}

.nav-tabs--no-underline {
	.mdc-tab-indicator {
		display: none;
	}
	border-bottom: none !important;
}

.accent-ripple {
	.mat-mdc-tab-ripple {
		border-radius: 8px 8px 0 0;

		.mat-ripple-element {
			background-color: colors.$accent;
			opacity: 0.2;
		}
	}
}

.tabs-loader-container {
	position: relative;
	min-height: 200px;
}

.au-nav-tabs {
	--mdc-tab-indicator-active-indicator-color: colors.$accent;

	.au-nav-tab.au-nav-tab {
		flex-grow: 0 !important;
		font-family: variables.$secondary-font-family;
		height: 60px;
		color: colors.$inactive-tab;
		opacity: 1;
		padding: 3px 12px 0;
		transition: background-color 200ms ease-in-out;
		font-size: mixins.px-to-rem(20);
		line-height: mixins.px-to-rem(30);
		font-weight: 600;
		border-radius: 8px 8px 0 0;
		box-sizing: border-box;

		mat-icon {
			color: colors.$inactive-tab;

			* {
				fill: colors.$inactive-tab;
			}
		}

		&:not(:last-child) {
			margin-right: 12px;
		}

		.mdc-tab__text-label {
			font-weight: bold;
			letter-spacing: normal;
		}

		&.active {
			background-color: white;
			box-shadow: colors.$secondary-text;

			.mdc-tab__text-label {
				color: colors.$accent;
			}

			mat-icon {
				color: colors.$accent;

				* {
					fill: colors.$accent;
				}
			}
		}

		&:hover:not(.active) {
			.mdc-tab__text-label {
				color: colors.$accent;
			}

			mat-icon {
				color: colors.$accent;

				* {
					fill: colors.$accent;
				}
			}
		}

		&:hover {
			.mdc-tab__ripple::before {
				display: none;
			}
		}
	}
}

.au-nav-tabs-content {
	@extend %tabs-content-container;

	&.round-border {
		border-radius: 8px;
	}
}

@include mixins.media-breakpoint-up(sm) {
	.au-nav-tabs-content {
		padding: 27px 32px 48px 32px;
	}

	.au-nav-tabs {
		.au-nav-tab.au-nav-tab {
			height: 70px;
			padding: 0 18px;
			margin-right: 12px;
			font-size: mixins.px-to-rem(22);
		}
	}
}

@include mixins.media-breakpoint-up(bootstrap-md) {
	.au-nav-tabs {
		.au-nav-tab.au-nav-tab {
			height: 80px;
			padding: 0 32px;
			margin-right: 24px;
			font-size: mixins.px-to-rem(24);
		}
	}
}
