@use 'colors';
@use 'mixins';

.profile-with-avatar {
	display: flex;
	flex-direction: row;
	align-items: center;

	.avatar {
		@include mixins.equal-size(40px);
		margin-right: 12px;
		border-radius: 100%;
		overflow: hidden;
		flex-shrink: 0;

		img {
			@include mixins.equal-size(100%);
			object-fit: cover;
		}
	}

	.avatar-placeholder {
		@include mixins.equal-size(100%);
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: colors.$accent;
		font-size: mixins.px-to-rem(18);
		font-weight: bold;
		color: white;
	}
}
