@use './colors';
@use './mixins';
@use './variables';

.au-filters,
.au-forms {
	.mat-mdc-select.mat-mdc-select {
		--mdc-typography-body1-font-size: 18px;
		--mdc-typography-body1-letter-spacing: normal;
	}
}

.mat-mdc-select-panel {
	&:hover {
		&::-webkit-scrollbar {
			display: inherit;
		}
	}

	/* total width */
	&::-webkit-scrollbar {
		display: block;
		width: 8px;
		height: 8px;
	}

	/* background of the scrollbar except button or resizer */
	&::-webkit-scrollbar-track {
		border-radius: 4px;
		background-color: colors.$background;
	}

	/* scrollbar itself */
	&::-webkit-scrollbar-thumb {
		background-color: colors.$scrollbar !important;
		border-radius: 4px;

		&:hover {
			background-color: colors.$accent !important;
		}
	}

	/* set button(top and bottom of the scrollbar) */
	&::-webkit-scrollbar-button {
		display: none;
	}
}

.au-select-placeholder {
	position: absolute;
	left: 0;
	right: 25px;
	color: colors.$mat-form-field-placeholder;
}

.action-buttons {
	display: flex;
	margin-bottom: 24px;

	au-techno-btn {
		width: 100%;
		min-width: 152px;
	}

	.save-btn {
		min-width: 152px;
		width: 100%;
		height: 50px;
		margin-bottom: 18px;
		background-color: colors.$primary;
		font-weight: 600;
		color: white;

		&:hover:not(:disabled) {
			background-color: colors.$accent;
		}
	}
}
