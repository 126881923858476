@forward './default-theme';
@forward './icons';
@forward './material-overrides';
@forward './tabs';
@forward './buttons';
@forward './typography';
@forward './utils';
@forward './profile-with-avatar';
@forward './modals';
@forward './cards';
@forward './horizontal-scroll';
@forward './forms';
@forward './badges';
@forward './layout';
@forward './filter-forms';
@forward './animated-point';
@forward './dt-styles';
@forward './scrollbar';
@forward './calendar-material-overrides';

@use 'mixins';
@use 'variables';
@use 'colors';
html {
	font-family: variables.$main-font-family;
	font-size: variables.$base-font-size;
	line-height: 1.15;
	height: 100%;
}

body {
	margin: 0;
	font-family: variables.$main-font-family;
	font-size: variables.$base-font-size;
	height: 100%;
}

a {
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}

:root {
	.mat-mdc-icon-button {
		outline: none;
	}

	.mat-mdc-list-base {
		padding-top: 0;
	}

	.mat-drawer-container {
		overflow: auto;
	}
}
