@use 'colors';

.active-scroll {
	position: relative;
	box-sizing: border-box;
	padding: 0 40px;

	.scroll-button {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 28px;
		top: 0;
		bottom: 0;
		color: colors.$accent;
		background-color: colors.$background;
		cursor: pointer;
		user-select: none;

		&.disabled {
			color: colors.$secondary-text;
			cursor: not-allowed;
			pointer-events: none;
		}

		&.left {
			left: 0;
			border-radius: 8px 0 0 8px;
		}

		&.right {
			right: 0;
			border-radius: 0 8px 8px 0;
		}
	}
}

.inactive-scroll {
	.scroll-button {
		display: none;
	}
}
