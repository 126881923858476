@use 'colors';
@use 'mixins';



@keyframes poppingCircle {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
