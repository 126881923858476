%spinner-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
}
