@use '@angular/material' as mat;
@use './variables';
@use 'colors';
@use './primary-palette.scss' as my-theme;

@include mat.elevation-classes();
@include mat.app-background();

$au-mat-typography: mat.m2-define-typography-config(
	$font-family: "'proxima-nova', Roboto, sans-serif",
	$button:
		mat.m2-define-typography-level(
			$font-family: variables.$secondary-font-family,
			$font-size: 15,
			$font-weight: 700,
			$letter-spacing: 0.75px,
		),
);

@include mat.all-component-typographies($au-mat-typography);

@include mat.typography-hierarchy($au-mat-typography);
@include mat.checkbox-density(-1);

html {
	color-scheme: light;
	@include mat.theme(
		(
			color: (
				primary: my-theme.$primary-palette,
				tertiary: my-theme.$tertiary-palette,
			),
			typography: (
				plain-family: Roboto,
				brand-family: Roboto,
			),
			density: 0,
		)
	);
}

// html.dark-mode {
// 	color-scheme: dark;
// 	@include mat.theme(
// 		(
// 			color: (
// 				primary: my-theme.$primary-palette,
// 				tertiary: my-theme.$tertiary-palette,
// 			),
// 			typography: (
// 				plain-family: Roboto,
// 				brand-family: Roboto,
// 			),
// 			density: 0,
// 		)
// 	);
// }
