@use './colors';

/*
nice-scroll - implement actual scrolling
visible-scroll - add always visible scroll
colored-scroll-track - add visible scroll-track

class="nice-scroll visible-scroll" - always visible scroll
class="nice-scroll colored-scroll-track" - scroll with light purple scroll-track
class="nice-scroll colored-scroll-track visible-scroll" -
 always visible scroll with light purple scroll-track
*/

.nice-scroll {
	&:hover {
		&::-webkit-scrollbar {
			display: inherit;
		}
	}

	&.visible-scroll {
		&::-webkit-scrollbar {
			display: block;
		}
	}

	/* total width */
	&::-webkit-scrollbar {
		display: none;
		width: 8px;
		height: 8px;
	}

	/* background of the scrollbar except button or resizer */
	&.colored-scroll-track {
		&::-webkit-scrollbar-track {
			border-radius: 4px;
			background-color: rgba(colors.$scrollbar, 0.3);
		}
	}

	/* scrollbar itself */
	&::-webkit-scrollbar-thumb {
		background-color: colors.$scrollbar !important;
		border-radius: 4px;

		&:hover {
			background-color: colors.$accent !important;
		}
	}

	/* set button(top and bottom of the scrollbar) */
	&::-webkit-scrollbar-button {
		display: none;
	}
}
