@use './colors';

.accent-icon {
	color: colors.$accent;

	* {
		fill: colors.$accent;
	}
}

.critical-icon {
	color: colors.$error;

	* {
		fill: colors.$error;
	}
}

.ghost-icon {
	color: colors.$secondary-text;

	* {
		fill: colors.$secondary-text;
	}
}

.white-transparent-icon {
	opacity: 0.5;
	color: colors.$white;

	* {
		fill: colors.$white;
	}
}

.success-icon {
	color: colors.$primary;

	* {
		fill: colors.$primary;
	}
}

.secondary-text-icon {
	color: colors.$secondary-text;

	* {
		fill: colors.$secondary-text;
	}
}

.primary-grey {
	color: colors.$primary-grey;

	* {
		fill: colors.$primary-grey;
	}
}

.ghost-grey-icon {
	color: rgba(colors.$secondary-text, 0.5);

	* {
		fill: rgba(colors.$secondary-text, 0.5);
	}
}

//Remove this. Its not part of current design
.ghost-orange-icon {
	color: colors.$ghost-orange;

	* {
		fill: colors.$ghost-orange;
	}
}

.magnifier-icon {
	font-size: 22px;
	right: 5px;
	position: relative;
	color: colors.$light-grey-text;
}

.nav-group-icon {
	color: colors.$secondary-text;

	* {
		fill: colors.$secondary-text;
		transition: fill 150ms ease-in;
	}

	&.active {
		color: colors.$light-accent;

		* {
			fill: colors.$light-accent;
		}
	}
}

.hovered-icon {
	&:hover {
		.nav-group-icon:not(.active) {
			color: colors.$accent;

			* {
				fill: colors.$accent;
			}
		}
	}
}

.white-icon {
	color: white;

	* {
		fill: white;
	}
}

.kpi-white-icon {
	*:not(circle) {
		fill: white;
	}

	circle {
		stroke: white;
	}
}

.status-icon {
	&.good-status {
		color: colors.$primary;

		* {
			fill: colors.$primary;
		}
	}

	&.warning-status {
		color: colors.$warning;

		* {
			fill: colors.$warning;
		}
	}

	&.bad-status {
		color: colors.$error;

		* {
			fill: colors.$error;
		}
	}

	&.null-status {
		color: colors.$secondary-text;

		* {
			fill: colors.$secondary-text;
		}
	}
}


.regret-icon-m,
.regret-icon-m.mat-mdc-icon-button {
	margin-right: -15px;
}
