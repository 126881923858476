@use './colors';
@use 'mixins';
@use "variables";

.au-cards-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: -10px;
}

.au-card-wrapper {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
}

.au-card {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	border: 1px solid colors.$card-border;
	border-radius: 16px;
	overflow: hidden;
	position: relative;
	padding: 24px;

	&:hover {
		box-shadow: colors.$elevation-xl;
	}
}

.au-content-card {
	position: relative;
	background: white;
	box-shadow: 0 15px 25px rgba(29, 81, 70, 0.05);
	border-radius: 8px;
	overflow: hidden;
	padding: 22px;
}

.mobile {
	display: block;
}

.status {
	border-radius: 8px;
	padding: 8px 12px;

	font-family: variables.$main-font-family;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 28px;

	&.active {
		background: colors.$completed-background;
		color: colors.$accent;
	}

	&.expired {
		background: colors.$light-red;
		color: colors.$thirtly-error;
	}
}

