$base-font-size: 16px;
$small-font-size: 14px;

$base-space: 28px;

$border-radius: 8px;
$breakpoints: (
	xs: 480px,
	sm: 600px,
	bootstrap-md: 768px,
	smd: 850px,
	md: 992px,
	lg: 1200px,
	xlg: 1275px,
	au-lg: 1440px,
	extra-large: 1980px,
);

// Application
$header-height: 60px;
$nav-sidebar-full-width: 212px;

// Font
$main-font-family: 'proxima-nova', Roboto, sans-serif;
$secondary-font-family: 'proxima-nova-condensed', Roboto, sans-serif;

$none: 0;
$extra-small: 4px;
$small: 8px;
$medium: 12px;
$large: 16px;
$xlarge: 20px;
$xxlarge: 24px;
$full: 48px;

// Paddings
$padding-xxs: 4px;
$padding-xs: 8px;
$padding-sm: 12px;
$padding-md: 16px;
$padding-lg: 20px;
$padding-xl: 24px;
$padding-xxl: 28px;
