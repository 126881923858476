@use './colors';

%badge {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-grow: 0;
	padding: 0 12px;
	height: 32px;
	border-radius: 8px;
	font-size: 16px;
	white-space: nowrap;
	font-weight: bold;
	user-select: none;
}

.badge {
	@extend %badge;

	&.info {
		height: 36px;
		font-weight: 600;
		background-color: colors.$background;
		color: colors.$secondary-text;
	}

	&.warning {
		background-color: colors.$light-warning;
		color: colors.$warning;
	}

	&.good {
		background-color: colors.$completed-background;
		color: colors.$primary;
	}

	&.error {
		background-color: colors.$light-red;
		color: colors.$secondary-error;
	}
}
