@use "sass:list";
@use './colors';

$unit: px;
$margin-key: 'm';
$padding-key: 'p';
$separator: '-';
$spacing: (
	('none', 0),
	('sxs', 12),
	('xs', 16),
	('sm', 24),
	('m', 32),
	('ms', 40),
	('lg', 48),
	('xlg', 64)
);
$sides: (('t', 'top'), ('r', 'right'), ('b', 'bottom'), ('l', 'left'));

@function sizeValue($key, $value) {
	@return if($key == 'none', 0, $value + $unit);
}

@each $space in $spacing {
	$spaceKey: list.nth($space, 1);
	$spaceValue: list.nth($space, 2);

	.#{$margin-key}#{$separator}#{$spaceKey} {
		margin: sizeValue($spaceKey, $spaceValue);
	}

	.#{$padding-key}#{$separator}#{$spaceKey} {
		padding: sizeValue($spaceKey, $spaceValue);
	}

	@each $side in $sides {
		$sideKey: list.nth($side, 1);
		$sideValue: list.nth($side, 2);

		.#{$margin-key}#{$separator}#{$sideKey}#{$separator}#{$spaceKey} {
			margin-#{$sideValue}: sizeValue($spaceKey, $spaceValue);
		}

		.#{$padding-key}#{$separator}#{$sideKey}#{$separator}#{$spaceKey} {
			padding-#{$sideValue}: sizeValue($spaceKey, $spaceValue);
		}
	}
}

.no-shadow {
	box-shadow: none;
}

.m-l-auto {
	margin-left: auto;
}

.m-r-auto {
	margin-right: auto;
}

.good {
	color: colors.$primary;
}

.good-with-background {
	background-color: colors.$completed-background;
	color: colors.$accent;
}

.warning {
	color: colors.$warning;
}

.warning-with-background {
	background-color: colors.$light-warning;
	color: colors.$secondary-warning;
}

.bad, .error {
	color: colors.$error !important;
}

.bad-with-background {
	background-color: colors.$light-red;
	color: colors.$thirtly-error;
}

.to-do {
	color: colors.$accent;
}

.to-do-with-background {
	background-color: colors.$light-accent;
	color: colors.$secondary-text;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-crosshair {
	cursor: crosshair;
}

.uppercase {
	text-transform: uppercase;
}

.width-100 {
	width: 100%;
}

.location-badge {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-height: 36px;
	padding: 0 12px;
	border-radius: 8px;
	background-color: colors.$background;
	color: colors.$secondary-text;
	font-size: 14px;
	font-weight: 500;
}

.remove-number-arrow {
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
}

.address-with-pin {
	display: flex;
	align-items: flex-start;
	margin-left: -4px;

	a {
		color: colors.$black-text;
		line-height: 30px;

		&:visited {
			color: colors.$black-text;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.mat-icon {
		margin-right: 7px;
		margin-top: 2px;
	}
}

.icon-with-text {
	display: inline-flex;
	align-items: center;

	.mat-icon {
		margin-right: 8px;
		margin-top: 2px;
	}

	p {
		margin: 0;
		line-height: 30px;
	}
}

.no-data {
	color: colors.$secondary-text !important;
}

.rounded {
	border-radius: 100%;
}

.flex-center-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-center-end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.flex-start {
	align-items: flex-start;
}

.accent {
	color: colors.$accent;
}

.d-block {
	display: block;
}

.d-flex {
	display: flex;

	&.align-center {
		align-items: center;
	}
}

.space-between {
	justify-content: space-between;
}

.d-none {
	display: none !important;
}

.au-sticky {
	position: sticky;
	top: 20px;
}

.not-allowed {
	/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
	::ng-deep .mat-select-trigger {
		cursor: not-allowed;
	}
}

.no-word-wrap {
	white-space: nowrap;
}

.horizontal-select {
	display: flex;
	align-items: center;
}

.select-label {
	margin: 0;
	font-size: 16px;
	line-height: 30px;
}

.scroll-x-auto {
	padding-bottom: 12px;
	overflow-x: auto;
}

.text-center {
	text-align: center;
}

.rotate-right-90 {
	transform: rotate(-90deg);
}

.visibility-hidden {
	visibility: hidden;
}

.align-text-wrapper {
	display: flex;
	align-items: center;
	gap: 14px;

	span {
		margin-top: 2px;
	}
}
