@use 'colors';
@use 'mixins';

.filters-container {
	.filters {
		display: flex;
		flex-direction: column;
	}

	.mat-mdc-form-field {
		min-width: 150px;
		width: 100%;

		input {
			color: colors.$mat-select-value;
		}
	}
}

@include mixins.media-breakpoint-down(sm) {
	.filters-container {
		.mat-mdc-form-field {
			&:not(:last-child) {
				margin-bottom: 38px;
			}
		}
	}
}

@include mixins.media-breakpoint-up(sm) {
	.filters-container {
		padding: 24px 32px;
		background-color: colors.$background;
		border-radius: 8px;
		overflow: hidden;

		.filters {
			flex-direction: row;
			flex-wrap: wrap;
			margin-left: -24px;
			margin-right: -24px;
		}

		.mat-mdc-form-field {
			width: calc(33.33333333% - 48px);
			flex: 1 1 auto;
			padding: 0 24px;
		}
	}
}
