// Project palette
//old theme variables
$primary-green: #37a684;
$secondary-green: #147165;
$ghost-grey: #8684a8;
$primary-grey: #6e7a97;
$table-border: #e6eeff;
$grey-text: $primary-grey;
$grey: #808080;

//new theme variables
$primary: #3b946f;
$primary-opacity: rgba(#3b946f, 0.5);
$accent: #1c4b31;
$accent-opacity: #1c4b3180;
$accent-light-opacity: rgba(#1c4b31, 0.15);
$light-accent: #e8edea;
$secondary-text: #5b5952;
$left-sidebar: #191710;
$completed-background: #8dc1731a;
$todo-label: #333d47;
$scrollbar: #899f90;
$separator: #e8e3da;
$completed-background-text: #5d8478;
$inactive-tab: #8a849a;
$separator-disabled: rgba(107, 102, 208, 0.25);
$container: #333d47;
$secondary-background: #fefcf8;

$light-orange: #fff6ef;
$light-red: #fff4f4;
$dark: #180e1e;
$thirtly-violet: #4653cc;
$background: #f6f4f0;
$warning: #ff993c;
$warning-background: #ea6114;
$secondary-warning: #ff4a00;
$light-warning: #fff2e8;
$error: #ff6a47;
$secondary-error: #e45858;
$thirtly-error: #cf0f0f;
$white: #ffffff;
$black: #000000;
$prioritize-high: #ff4d26;
$prioritize-medium: #ffc700;
$prioritize-low: #cbd1d7;
$ghost-orange: #ad9b8e;

// element colors
$new-notification: #fd7575;
$error-toast: #ffdddd;
$border-color: #e8e3da;
$mat-select-value: #000000;
$tab-card-label: $secondary-text;
$light-grey-text: $secondary-text;
$black-text: #000000;
$card-border: $border-color;
$light-orange-text: #897464;
$light-red-text: #956a6a;
$input-border: #d3d9eb;
$mat-divider: #cfd6e9;
$inactive-tab: $secondary-text;
$mat-form-field-placeholder: $secondary-text;
$kpi-no-status: $secondary-text;
$disabled-lightgray: #a39fa5;

// mat3 colors
$mat3-primary-green: #2f7756;
$mat3-background: #e8e3da;

// buttons
$primary-button-text: $primary;
$primary-button-border: rgba($primary, 0.5);
$primary-button-background: $primary;
$primary-button-disabled: #97afa8;

$secondary-button-text: $accent;
$secondary-button-border: rgba($accent, 0.5);
$secondary-button-background: $accent;
$secondary-button-text-disabled: #7977a3;

$attention-button-text: $error;
$attention-button-border: rgba($error, 0.5);
$attention-button-background: $error;
$attention-button-text-disabled: #c5a6a6;

$ghost-button-background: #f5f8ff;

//new shadows
$accent-shadow: 0 4px 10px #1c4b31;
$elevation-xs: 0px 2px 2px 0px #1c4b310f;
$elevation-sm:
	0px 2px 4px 0px #0f1c330f,
	0px 2px 2px 0px #1c4b3112;
$elevation-md:
	0px 2px 4px -4px #1c4b310a,
	0px 3px 8px -2px #1c4b311a;
$elevation-lg:
	0px 2px 8px -4px #1c4b310d,
	0px 10px 14px -4px #1c4b3114;
$elevation-xl:
	0px 6px 8px -4px #1c4b310a,
	0px 16px 20px -4px #1c4b311a;
$elevation-xl2: 0px 24px 40px -10px #1c4b3129;
$elevation-xl3: 0px 32px 56px -12px #1c4b3129;

// Gradients
$cta-gradient: linear-gradient(180deg, #c4a6a6 0%, #1a4460 70.5%);
