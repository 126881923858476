@use 'sass:math';
@use 'sass:list';
@use 'sass:map';
@use 'sass:string';
@use 'variables';
@use 'colors';

@function px-to-rem($pixels, $context: variables.$base-font-size) {
	@if (math.is-unitless($pixels)) {
		$pixels: $pixels * 1px;
	}

	@if (math.is-unitless($context)) {
		$context: $context * 1px;
	}

	@return math.div($pixels, $context) * 1rem;
}

@mixin equal-size($size) {
	width: $size;
	height: $size;
}

// @include media-breakpoint-up(sm)
@mixin media-breakpoint-up($breakpoint) {
	@if map.has-key(variables.$breakpoints, $breakpoint) {
		$breakpoint-value: map.get(variables.$breakpoints, $breakpoint);

		@media (min-width: $breakpoint-value) {
			@content;
		}
	} @else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

//@include media-breakpoint-down(sm) {}
@mixin media-breakpoint-down($breakpoint) {
	@if map.has-key(variables.$breakpoints, $breakpoint) {
		$breakpoint-value: map.get(variables.$breakpoints, $breakpoint);

		@media (max-width: ($breakpoint-value - 1)) {
			@content;
		}
	} @else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

// @include media-breakpoint-between(sm, md) {}
@mixin media-breakpoint-between($lower, $upper) {
	@if map.has-key(variables.$breakpoints, $lower) and
		map.has-key(variables.$breakpoints, $upper)
	{
		$lower-breakpoint: map.get(variables.$breakpoints, $lower);
		$upper-breakpoint: map.get(variables.$breakpoints, $upper);

		@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
			@content;
		}
	} @else {
		@if (map.has-key(variables.$breakpoints, $lower) == false) {
			@warn 'Your lower breakpoint was invalid: #{$lower}.';
		}

		@if (map.has-key(variables.$breakpoints, $upper) == false) {
			@warn 'Your upper breakpoint was invalid: #{$upper}.';
		}
	}
}

// @include font-face(Samplinal, fonts/Samplinal, 500, normal, eot woff2 woff);
@mixin font-face(
	$name,
	$path,
	$weight: null,
	$style: null,
	$exts: eot woff2 woff ttf svg
) {
	$src: null;

	$extmods: (
		eot: '?',
		svg: '#' + str-replace($name, ' ', '_'),
	);

	$formats: (
		otf: 'opentype',
		ttf: 'truetype',
	);

	@each $ext in $exts {
		$extmod: if(map.has-key($extmods, $ext), $ext + map.get($extmods, $ext), $ext);
		$format: if(map.has-key($formats, $ext), map.get($formats, $ext), $ext);
		$src: list.append(
			$src,
			url(string.quote($path + '.' + $extmod)) format(string.quote($format)),
			comma
		);
	}

	@font-face {
		font-family: string.quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
		font-display: auto;
	}
}

@mixin layout($padding: 0 75px) {
	background: #ffffff;

	width: 100%;
	height: 100%;

	padding: $padding;
	box-sizing: border-box;
	box-shadow: 0 15px 25px rgba(29, 81, 70, 0.05);
	border-radius: variables.$border-radius;
}

@mixin tag() {
	display: inline-block;
	padding: 0 12px;
	font-size: px-to-rem(14);
	line-height: px-to-rem(30);
	font-weight: 600;
	border-radius: 8px;
	transition: all 0.1s linear;
	background-color: var(--mat-sys-surface-container);
}

@mixin cmms-workspace-container($class) {
	.#{$class} {
		width: 600px;
		padding: 32px 40px;
		box-sizing: border-box;
		overflow-x: hidden;
	}

	@include media-breakpoint-up(lg) {
		.#{$class} {
			width: 828px;

			&.fullscreen {
				width: 100%;
			}
		}
	}
}

@mixin cmms-maintenance-content() {
	.maintenance-content {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.maintenance-row {
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;
			gap: 12px;

			.l-control {
				width: 370px;
			}

			.m-control {
				width: 192px;
			}

			.s-control {
				width: 162px;
			}

			.l-control,
			.m-control,
			.s-control {
				max-width: 100%;
				height: fit-content;
			}
		}
	}
}

@mixin cmms-new-entity-container($class) {
	.sections {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-bottom: 16px;
	}
}

@mixin cmms-edit-container($class) {
	.#{$class} {
		position: relative;
	}

	.edit-name {
		margin-right: 32px;
	}

	.icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		@include equal-size(32px);
		cursor: pointer;
		border-radius: 100%;

		position: absolute;
		top: 36px;
		right: 32px;

		&:hover {
			background-color: rgba(colors.$accent, 0.05);
		}

		.mat-icon {
			user-select: none;
		}
	}

	.button-delete {
		color: colors.$error;
	}

	.sections {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-bottom: 16px;
	}

	.image-section {
		margin-bottom: 16px;
	}
}

@mixin cmms-priority-and-category() {
	.priority-and-category {
		display: flex;
		flex-wrap: wrap;
		gap: 18px;
		margin-bottom: 8px;

		@include media-breakpoint-up(bootstrap-md) {
			flex-wrap: nowrap;
			gap: 0;
			align-items: center;

			.priority,
			.category {
				width: 50%;
			}
		}
	}
}
