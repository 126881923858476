@use './variables';
@use './mixins';

.au-mat-calendar {
	.mat-calendar-table-header {
		th {
			font-size: mixins.px-to-rem(14);
			line-height: mixins.px-to-rem(32);
			font-weight: 600;
		}
	}

	.mat-calendar-body-label {
		height: 38px;
		padding: 0 !important;
		visibility: hidden;

		&[colspan='7'] {
			display: none;
		}
	}

	.mat-calendar-body-cell-container {
		height: 38px;
		padding: 0 !important;
	}

	.mat-calendar-body-cell-content {
		@include mixins.equal-size(36px);
		margin: auto;
		font-size: mixins.px-to-rem(14);
		line-height: mixins.px-to-rem(32);

		&.mat-focus-indicator {
			position: relative;
			top: unset;
			left: unset;
		}

		&.mat-calendar-body-selected {
			&.mat-calendar-body-today {
				box-shadow: none;
			}
		}
	}

	.mat-calendar-body-cell::before,
	.mat-calendar-body-cell::after,
	.mat-calendar-body-cell-preview {
		top: 26.3%;
		height: 47.4%;
	}

	.mat-calendar-body-range-end {
		&:not(.mat-calendar-body-in-comparison-range) {
			&::before {
				width: 50%;
			}
		}
	}

	.mat-calendar-body-range-start {
		&:not(.mat-calendar-body-in-comparison-range) {
			&::before {
				left: 50%;
				width: 50%;
			}
		}
	}

	.mat-calendar-body-preview-start {
		.mat-calendar-body-cell-preview {
			border-left: none;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			width: 50%;
			left: 50%;
		}
	}

	.mat-calendar-body-preview-end {
		.mat-calendar-body-cell-preview {
			border-right: none;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			width: 50%;
		}
	}
}
